$orange: #ec7404;
$darkGrey: #464646;
$grey: #c8c8c8;
$lighterGrey: #eeeeee;
$lightestGrey: #f5f5f5;

@import '../node_modules/bootstrap/scss/bootstrap.scss';

#agencia-digital-dropdown {
  background-color: #fbfbfb;
  height: 100%;
  list-style: none;
  min-height: 100vh;
  width: 100%;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    outline: 0;
  }

  .sub_header {
    width: 100%;
    background: #ec7403;
    height: 47px;
    margin-bottom: 25px;
    padding: 0;
    border: 0;
    border-radius: 0;
    position: relative;
  }

  /******* start content dropdown *******/

  #dropdown-chat {
    display: inline-block;
  }

  #dropdown-chat .btn_dropdown {
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 17px 16px 15px 48px;
    border: 0;
    background-color: #464646;
    position: relative;
  }

  #dropdown-chat .btn_dropdown .caret {
    border-top-color: #ffffff;
    transform: rotate(0deg);
    position: relative;
    right: -3px;
  }

  #dropdown-chat .btn_dropdown:focus {
    outline: 0;
  }

  #dropdown-chat .dropdown-menu {
    box-shadow: 0 16px 16px 0px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 16px 16px 0px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: 0;
    min-width: 355px;
    padding: 0;
  }

  #dropdown-chat.open .btn_dropdown {
    background-color: #ffffff;
    background-image: none;
    color: #ec7403;
  }

  #dropdown-chat .btn_dropdown .bubble-notification {
    content: '';
    width: 20px;
    height: 18px;
    display: inline-block;
    background-image: url(./img/icn-bubble-empty-btn.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px 18px;
    position: absolute;
    left: 16px;
    top: 15px;
  }

  #dropdown-chat.open .btn_dropdown .bubble-notification {
    background-image: url(./img/icn-bubble-empty.svg);
  }

  #dropdown-chat.open .btn_dropdown .caret {
    border-top-color: #ec7403;
    transform: rotate(-180deg);
  }

  #dropdown-chat .dropdown-menu .container-chats {
    padding: 0 17px;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li + li {
    border-top: solid 1px #f3f3f3;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li a {
    display: block;
    position: relative;
    padding: 16px 0;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li a:hover,
  #dropdown-chat .dropdown-menu .container-chats ul li a:focus {
    text-decoration: none;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li a .data-chat {
    display: inline-block;
    vertical-align: middle;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li a .data-chat h5 {
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252220;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li a .data-chat p {
    font-family: Arial;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ec7000;
    padding-top: 4px;
  }
  #dropdown-chat .dropdown-menu .container-chats ul li a .image-chat {
    display: inline-block;
    width: 35px;
    height: 40px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    border: 1px solid #ddd;
    margin-right: 10px;
    overflow: hidden;
    vertical-align: middle;
  }
  #dropdown-chat .dropdown-menu .container-chats ul li a .image-chat img {
    width: 100%;
    height: auto;
  }
  #dropdown-chat .dropdown-menu .container-chats ul li a .content-right {
    position: absolute;
    right: 0;
    top: calc(50% - 13px);
    display: inline-block;
  }
  #dropdown-chat .dropdown-menu .container-chats ul li a .content-right .counter {
    display: inline-block;
    height: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    background-color: #eb7306;
    vertical-align: middle;
  }
  #dropdown-chat .dropdown-menu .container-chats ul li a .content-right .counter * {
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 2px 4px;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li a .content-right .arrow-right {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url(./img/icn-chevron.svg) no-repeat center center;
    vertical-align: middle;
    margin-left: 6px;
  }

  /******* si el dropdown tiene un nuevo mensaje cambia el icono  *******/

  #dropdown-chat.new-msj .btn_dropdown .bubble-notification {
    background-image: url(./img/icn-bubble-empty-orange.svg);
  }

  #dropdown-chat.new-msj .btn_dropdown .bubble-notification * {
    font-family: Arial;
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  #dropdown-chat.new-msj.open .btn_dropdown .bubble-notification {
    background-image: url(./img/icn-bubble.svg);
  }

  /******* si el dropdown tiene un nuevo mensaje cambia el icono  *******/

  #dropdown-chat .dropdown-menu .container-chats ul li a .content-right .btn-chat {
    display: block;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    background-color: #464646;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #ffffff;
    padding: 8px 16px 8px 10px;
    vertical-align: middle;
  }

  #dropdown-chat
    .dropdown-menu
    .container-chats
    ul
    li
    a
    .content-right
    .btn-chat
    .bubble-notification {
    content: '';
    width: 18px;
    height: 16px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px 16px;
    position: relative;
    left: 0px;
    background-image: url(./img/icn-bubble-empty-btn.svg);
    vertical-align: middle;
    text-align: center;
    margin-right: 4px;
  }

  #dropdown-chat
    .dropdown-menu
    .container-chats
    ul
    li
    a
    .content-right
    .btn-chat
    .bubble-notification
    * {
    font-family: Arial;
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: block;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li .telephone,
  #dropdown-chat .dropdown-menu .container-chats ul li .mail {
    font-family: Arial;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 19px;
    letter-spacing: normal;
    color: #757575;
    vertical-align: middle;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li .telephone {
    padding-bottom: 5px;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li a + a {
    padding-top: 4px;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li .mail:hover * {
    text-decoration: underline;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li .telephone *,
  #dropdown-chat .dropdown-menu .container-chats ul li .mail * {
    display: inline-block;
    vertical-align: middle;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li .telephone:before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url(./img/icn-phone.svg) no-repeat center center;
    margin-right: 13px;
    float: left;
  }

  #dropdown-chat .dropdown-menu .container-chats ul li .mail:before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url(./img/icn-mail.svg) no-repeat center 60%;
    margin-right: 13px;
    float: left;
  }

  /***************
      RESPONSIVE
  ****************/
  @media only screen and (max-width: 640px) {
    #dropdown-chat .dropdown-menu {
      min-width: 100%;
      width: 100vw;
    }
    #dropdown-chat {
      position: static;
    }
  }
}
